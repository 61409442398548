// window.addEventListener('scroll', () => {
//     document.body.style.setProperty('--scroll', window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
// }, false);

// document.addEventListener('DOMContentLoaded', () => {
//   // Get all "navbar-burger" elements
//   const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

//   // Add a click event on each of them
//   $navbarBurgers.forEach(el => {
//     el.addEventListener('click', () => {
//       // Get the target from the "data-target" attribute
//       const target = el.dataset.target;
//       const $target = document.getElementById(target);

//       // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
//       el.classList.toggle('is-active');
//       $target.classList.toggle('is-active');
//     });
//   });
// });

window.addEventListener("scroll", function() {
  var header = document.querySelector("nav");
  header.classList.toggle("color-nav", this.window.scrollY > 0);
  header.classList.toggle("shadow", this.window.scrollY > 0);
});
